import * as Sentry from '@sentry/remix';
import { CacheProvider } from '@emotion/react';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { hydrateRoot } from 'react-dom/client';
import { startTransition, useEffect } from 'react';
import createEmotionCache from '@emotion/cache';

Sentry.init({
  dsn: 'https://43cfc589be8c54f4c92f58f02743cdf0@o4507927257022464.ingest.us.sentry.io/4507927295950848',
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // Replay is only available in the client
    Sentry.replayIntegration(),
  ],
  environment: window.BENV.STAGE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api-public\.navace\.com\.au/,
    /^https:\/\/api-public-dev\.navace\.com\.au/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  enabled:
    window.location.hostname !== 'localhost' &&
    (window.BENV.STAGE === 'dev' || window.BENV.STAGE === 'prod'),
});

const hydrate = () => {
  const emotionCache = createEmotionCache({ key: 'css' });

  startTransition(() => {
    hydrateRoot(
      document,
      <CacheProvider value={emotionCache}>
        <RemixBrowser />
      </CacheProvider>
    );
  });
};

if (typeof requestIdleCallback === 'function') {
  requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
